import { render, staticRenderFns } from "./ButtonToolbarSize.vue?vue&type=template&id=80366738&"
import script from "./ButtonToolbarSize.vue?vue&type=script&lang=js&"
export * from "./ButtonToolbarSize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports